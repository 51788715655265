<template>
  <div class="home accept">
    <template v-if="approved">
      <div class="processed">
        <h1 style="font-weight:700;">Tak fordi du sender med GLS!</h1>
        <p>Din ordre med Betalings ID. {{ orderId }} er gennemført, og du vil inden for 10 minutter modtage en QR-kode på mail.</p>
        <p>Du skal blot tage din pakke samt QR-kode med ned i PakkeShoppen, så sender vi din pakke godt afsted.</p>
      </div>
    </template>
    <template v-else>
      <div v-if="!error" class="processing">
        <p>Tjekker og gennemfører din ordre...</p>
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div
        v-else
        class="accept text-center"
        style="display: flex;flex-direction: column;"
      >
        <h1>Der er desværre sket en fejl</h1>
        <div v-html="errorMessage" class="processed"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Accept",
  data() {
    return {
      approved: false,
      error: false,
      errorMessage: "",
      orderId: null
    };
  },
  async created() {
    //this.$gtag.pageview(this.$route);
    try {
      this.approved = false;
      this.error = false;
      this.errorMessage = "";
      this.orderId = null;
      const urlParams = new URLSearchParams(window.location.search);
      const oid = urlParams.get("orderid");
      this.orderId = oid;
      this.approved = true;
    } catch (e) {
      
      this.error = e.response.data.error;
      this.errorMessage = e.response.data.message;
    }
  }
};
</script>

<style scoped>
.accept {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}
p.error {
  font-size: 16px !important;
}
.processing,
.processed {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #172154 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
