<template>
  <div class="home">
    <div
      class="d-flex flex-column align-items-center"
      style="margin-top:100px;"
    >
      <h1>Bankoplysninger</h1>
      <template v-if="!success">
        <div class="col-sm-6">
          <p>
            Vi har forsøgt at sætte pengene tilbage på det kort som du brugte
            til at købe labels med, men vores automatiske proces fik en fejl fra
            vores betalings gateway, og vi er derfor nødt til at behandle denne
            proces manuelt.
          </p>
          <p>
            Vi har i den forbindelse brug for dine bankkontooplysninger, så vi
            kan lave en bankoverførsel til dig i stedet. Eks. (Reg.nr. 1234
            Konto nr. 0001234567)
          </p>
        </div>
        <div class="col-sm-6">
          <b-form>
            <label for="inline-form-input-name">Reg.nr.</label>
            <b-form-input
              class="mb-2 mr-sm-2 mb-sm-0"
              placeholder="Eks. 1234"
              max="4"
              :state="validateState('regnr')"
              type="number"
              v-model.trim="$v.regnr.$model"
              aria-describedby="regnr-feedback"
              @focusout="leftRegnrField = true"
              @input="noInputReg = false"
            ></b-form-input>
            <b-form-invalid-feedback id="regnr-feedback"
              >Dette felt kræver 4 tal</b-form-invalid-feedback
            >

            <label for="kontonr">Konto nr.</label>
            <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input
                id="kontonr"
                placeholder="Eks. 0000123456"
                max="10"
                type="number"
                :state="validateState('kontonr')"
                v-model.trim="$v.kontonr.$model"
                aria-describedby="kontonr-feedback"
                @focusout="leftKontoField = true"
                @input="noInputKonto = false"
              ></b-form-input>
              <b-form-invalid-feedback id="kontonr-feedback"
                >Dette felt kræver 10 tal</b-form-invalid-feedback
              >
            </b-input-group>
            <b-button
              variant="primary"
              class="float-right mt-2"
              :disabled="!noInputKonto && !noInputReg ? $v.$anyError : true"
              @click="send()"
              >Send</b-button
            >
          </b-form>
        </div>
      </template>
      <div class="col-sm-6" v-else>
        <h1>Tak, vi har modtaget dine bankoplysninger</h1>
        <p>
          Vi behandler din refundering hurtigst muligt, og overføre beløbet til
          din bank.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  numeric
} from "vuelidate/lib/validators";
import { LabelOnlineService } from "@/services/LabelOnlineService";
export default {
  name: "BankTransfer",
  data() {
    return {
      id: null,
      regnr: null,
      kontonr: null,
      leftRegnrField: false,
      leftKontoField: false,
      noInputReg: true,
      noInputKonto: true,
      success: false
    };
  },
  validations: {
    regnr: {
      required,
      numeric,
      maxLength: maxLength(4),
      minLength: minLength(4)
    },
    kontonr: {
      required,
      numeric,
      maxLength: maxLength(10),
      minLength: minLength(10)
    }
  },
  created() {
    this.id = this.$route.query.id;
    //this.$gtag.pageview(this.$route);
  },
  methods: {
    async send() {
      try {
        await LabelOnlineService.sendBankInformation({
          refundId: this.id,
          accountNumber: this.kontonr,
          registrationNumber: this.regnr
        });
        this.success = true;
      } catch (err) {
        console.error(err);
      }
    },
    validateState(name) {
      if (name === "kontonr" && this.leftKontoField) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      } else if (name === "regnr" && this.leftRegnrField) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    }
  }
};
</script>

<style scoped></style>
