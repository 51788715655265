<template>
  <div class="home container">
    <p></p>
    <p></p>
    <p><b>Cookies are:</b></p>

    <p>
      Small text files stored on your computer, enabling an analysis of your
      website use. The information stored by cookies can be read by the website
      when you visit it again at a later date. On the one hand cookies are
      necessary to ensure that all processes on the site work smoothly. On the
      other hand cookies can make the re-use of the site easier for the user:
      Information stored by cookies at the first visit, e. g. language settings
      or user names, do not need to be entered again. Hence, the website adjusts
      itself to a certain extent to your individual needs under the condition
      that the same device will be used.
    </p>

    <p><b>That’s why GLS uses cookies: </b></p>

    <p>
      To provide you an optimal service, adapted to your customer needs. For
      instance, some information you have already received at the first visit,
      will not be displayed once more.<br />
      Activities such as the login and logout process can be facilitated,
      information you already searched for will be displayed faster. The
      performance of the website can be increased.<br />
      Of course, GLS makes organisational and technical provisions to protect
      your personal data and to avoid loss of information and illegal
      conduct.<br />
      Please find more information in our Privacy Policy.
    </p>

    <p>
      <u
        ><a
          href="/GROUP/en/privacy-policy-group"
          data-analytics="click"
          data-analytics-action="Click"
          data-analytics-category="Inline Link"
          >Go to Privacy Policy</a
        ></u
      >
    </p>

    <ul>
      <li>
        “_ga”, “_gat” and “_gid” (for Google Analytics, for detailed explanation
        see
        <a
          href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics="click"
          data-analytics-action="Click"
          data-analytics-category="Inline Link"
          >https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a
        >
        )
      </li>
      <li>
        “gls-cookie-policy“ (used to store acceptance of this Cookie Policy)
      </li>
      <li>“JSESSIONID“ (session id of your website user session)</li>
    </ul>

    <p>
      <b>How you can disable cookies: </b>
      <br />
      Browser settings can be adapted easily. Thus, you can also disable cookies
      without much effort. <br />
      Please find a short introduction, how to deactivate cookies for your
      browser.
    </p>

    <p>
      <i>Mozilla Firefox: </i> <br />
      1. Open the Firefox browser. <br />
      2. Click on the menu button and choose “Options”. Select the Privacy
      panel. <br />
      3. Set “Firefox will:” to “Use customs settings for history“. <br />
      4. Remove the check mark from “Accept cookies from sites“ to enable
      cookies, and uncheck to disable them. <br />
      5. Close the about:preferences page. Any changes you’ve made will
      automatically be saved.
    </p>

    <p>
      <i>I</i><i>nternet Explorer:</i> <br />
      1. Open the Internet Explorer. <br />
      2. Click the “Tools” button and then click “Internet Options”. Click the
      Privacy tab, and then, under “Settings”, move the slider to the top to
      block all cookies. <br />
      3. Click on ”OK“.
    </p>

    <p>
      <i>Google Chrome: </i> <br />
      1. Open Google Chrome. <br />
      2. Select the Chrome menu icon. <br />
      3. Select “Settings”. <br />
      4. Near the bottom of the page, select “Show advanced settings”. In the
      Privacy section, select ”Content settings“. Select ”Block sites from
      setting any data“. Select “Done”.
    </p>

    <p>
      <i>Safari:</i> <br />
      1. Open Safari. <br />
      2. Choose ”Preferences” and click on ”Privacy“. <br />
      3. Select the option “Always block”.
    </p>
  </div>
</template>

<script>
export default {
  name: "CookieInfo",
  
  created(){
      //this.$gtag.pageview(this.$route);
  }
};
</script>

<style scoped></style>
