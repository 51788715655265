<template>
 <div class="home container">
   <div id="app">
  <iframe :src="'https://dawa.aws.dk/'"></iframe>

  </div>
 </div>

 <!-- 'https:/gls-group.eu/DK/da/pakkeshop-betingelser' -->
  <!-- <div class="home container">
    <p></p>
    <p></p>

    <h6>1. Anvendelse</h6>
    <p>
      1.1. Disse betingelser gælder for alle aktiviteter udført af General
      Logistics System Denmark A/S, herefter kaldet GLS, i forbindelse med køb
      af E-Label.
    </p>
    <p>
      1.2. Medmindre det er nævnt i disse Betingelser, vil reglerne i Nordisk
      Speditørforbunds Almindelige Bestemmelser (NSAB 2015) være gældende.
    </p>

    <h6>2. Transportydelsens omfang</h6>
    <p>
      2.1. GLS tilrettelægger og gennemfører distribution af pakker – herunder
      sortering og levering.
    </p>
    <p>
      2.2. Pakker sendt med E-label må maksimalt veje 20 kg. Minimum og maksimum
      størrelse som GLS distribuerer er, Minimum 10 x 15 x 1cm Maksimum 200 cm
      på længste side, og maksimum 300 cm i længde + omkreds. Til private
      modtagere i Sverige maksimum 140 cm på længste side.
    </p>
    <p>2.3. Der kan kun sendes pakker til lande inden for EU.</p>
    <p>
      2.4. Pakker kan kun udleveres ved henstilling på et nærmere angivet sted
      efter skriftlig aftale (fuldmagt) herom mellem GLS og modtager/afsender.
      GLS betragter pakken som korrekt leveret, når pakken er henstillet på det
      aftalte sted.
    </p>
    <p>
      2.5. GLS kan levere til enhver person på adressen. Det er modtagers egen
      opgave at viderebringe til en evt. att. Person. I visse lande kan der
      forekomme nabolevering, hvis modtager ikke træffes på adressen.
    </p>
    <p>
      2.6. Hvis GLS ikke træffer nogen på modtageradressen, indlevere GLS pakken
      i en GLS PakkeShop eller på et GLS depot. Modtager får information herom.
      Efter 10 hverdage returnerer GLS pakken til afsender.
    </p>
    <p>
      2.7. GLS forbeholder sig ret til at levere til en anden PakkeShop end af
      den kunden valgte.
    </p>
    <p>
      2.8. GLS kan afvise at modtage en pakke, eller returnere en pakke, som
      ikke er forsvarligt emballeret, eller ikke er egnet til pakkedistribution.
    </p>

    <h6>3. Distribution</h6>
    <p>
      3.1. GLS’ kunde er ansvarlig for tilstrækkelig og forsvarlig emballering
      samt mærkning med én (og kun én label) fyldestgørende label pr. pakke.
      Kunden skal være opmærksom på, at pakker, der er strappet eller tapet
      sammen, og kun har én label, ikke erstattes, hvis de separeres fra
      hinanden. I så fald erstatter GLS kun den pakke, der har labelen på. GLS
      laver ikke kontrol af emballage og erstatter ikke beskadigelser på pakker,
      hvis det vurderes, at emballeringen ikke var tilstrækkelig og forsvarlig.
    </p>
    <p>
      3.2. Pakker skal være emballeret, så de kan behandles i et automatisk
      sorteringsanlæg, hvor det ikke er muligt at tage ekstra hensyn til
      pileafmærkninger, glasmærker, forsigtighedsmærker og lignende.
    </p>
    <p>
      3.3. Porcelæn, glas, teknisk udstyr, herunder fladskærme, computer-, it-,
      laserudstyr og andre skrøbelige varer skal emballeres i en kraftig
      yderemballage (papkasser eller andet transportegnet emballage). Den indre
      emballage skal bestå af tætsiddende, stødabsorberende materiale (ved
      teknisk udstyr skal det være formstøbt). Emballagen skal passe til
      indholdet. Alle hulrum skal fyldes med et velegnet materiale til at
      beskytte indholdet. Afsender skal sikre, at der ikke kan ske forskydning,
      tryk eller stød, hverken genstandene imellem eller mellem indholdet og
      pakkens yderemballage.
    </p>
    <p>
      3.4. Skade forårsaget af rystelser på interne komponenter, der ikke er
      fikseret optimalt under transporten, dækkes ikke af GLS
    </p>
    <p>
      3.5. Glasemballerede varer, og varer med flydende indhold skal sendes i en
      yderemballage, der er velegnet til distribution med GLS. Der skal anvendes
      en indre emballage, der beskytter varerne mod ydre påvirkninger og som
      absorberer og forhindrer evt. udvisning af varerne. Afsender skal sikre,
      at der ikke kan ske forskydning, tryk eller stød, hverken genstandene
      imellem eller mellem indholdet og pakkens yderemballage.
    </p>
    <p>
      3.5.1. I tilfælde af skade på varer, der risikere at skade personel,
      materiel eller andre pakker, forbeholder GLS sig retten til at destruere
      indholdet.
    </p>
    <p>3.6. GLS Distribuerer ikke:</p>
    <p>3.6.1. Til postboksadresser</p>
    <p>3.6.2. Dæk</p>
    <p>
      3.6.3. Uemballeret gods eller gods som ikke er egnet til
      pakkedistribution.
    </p>
    <p>
      3.6.4. Farligt gods (dog undtaget begrænset mængde LQ). Begrænset mængde
      (LQ) er ikke muligt til alle eksport destinationer.
    </p>
    <p>3.6.5. Levende eller døde dyr samt urner.</p>
    <p>
      3.6.6. Våben, våbenattraper herunder softguns, og våbendele samt
      aktiv/inaktiv ammunition eller eksplosive elementer.
    </p>
    <p>3.6.7. Let fordærvelige varer eller tøris.</p>
    <p>3.6.8. Kontanter</p>
    <p>
      3.6.9. Varer, som det strider mod lovgivningen at importere, eksportere
      eller distribuere i afsender-, modtager- eller transitlande.
    </p>
    <p>
      3.6.10. Afgiftspligtige varer som f.eks. cigaretter, tobak og alkohol til
      levering uden for Danmark.
    </p>
    <p>
      3.6.11. Pakker, som det strider mod gældende sanktionslove at distribuere,
      f.eks. pga. indhold, tiltænkt modtager eller det land, de skal sendes fra
      eller til. Sanktionslove omfatter alle love, forskrifter og vedtægter, der
      pålægger lande, enkeltpersoner eller enheder sanktioner (herunder
      handelsrestriktioner og økonomiske sanktioner), herunder uden
      begrænsninger de sanktioner, der pålægges af FN, EU og EU-medlemsstater.
    </p>
    <p>
      3.7. GLS kan uden varsel afvise eller afbryde distribution ved mistanke om
      ovennævnte.
    </p>
    <p>
      3.8. Kunden er forpligtet til at erstatte ethvert direkte eller indirekte
      tab, om påføres GLS, GLS’ Distributionspartnere eller andre kunder som
      følge af indhold nævnt under pkt. 3.6.1 til 3.6.12 og ethvert direkte
      eller indirekte tab som følge af utilstrækkelig emballering, jf. pkt. 3.2
      til 3.5.
    </p>
    <p>
      3.9. GLS er ikke forpligtet til at undersøge, om pakken indeholder varer
      listet i pkt. 3.6.1 til 3.6.12 eller at undersøge, hvorvidt emballeringen
      er tilstrækkelig, jf. pkt. 3.2 til 3.5.
    </p>

    <h6>4. Betaling, modtagelse og ordrebekræftelse</h6>
    <p>
      4.1. Pakker der er oprettet online, betales enten via betalingskort eller
      mobilepay.
    </p>
    <p>4.2. GLS trækker beløbet efter levering af online pakkelabel.</p>
    <p>
      4.3. GLS refunderer ikke det allerede betalte beløb ved returnering af
      pakker.
    </p>
    <p>
      4.3. Kunden vil efter bestilling af pakkelabel, modtage en elektronisk
      ordrebekræftelse.
    </p>

    <h6>5. Fortrydelsesret og tilbagebetaling</h6>
    <p>
      5.1. Ved køb af online pakkelabel sker leveringen straks, hvorfor
      fortrydelsesretten bortfalder i henhold til forbrugeraftaleloven.
    </p>
    <p>
      5.2. Det er muligt for kunden at foretage ændringer eller fortryde sit køb
      indtil ydelsen er taget i brug eller senest 14 dage efter kunden har
      modtaget ordrebekræftelsen.
    </p>
    <p>
      5.2.1. Hvis kunden ønsker at benytte fortrydelsesretten, skal
      fortrydelsesformularen, som fremgår på vores hjemmeside, udfyldes og
      sendes til GLS.
    </p>
    <p>
      5.3. Benytter kunden fortrydelsesretten, og ønsker at få tilbagebetalt
      sine penge, vil dette ske hurtigst muligt, dog senest efter 14 dage.
    </p>

    <h6>6. Uanbringeligt gods</h6>
    <p>
      6.1. GLS sælger uanbringeligt gods (hvor det ikke har været muligt at
      fastslå rette ejermand) på offentlig auktion efter 90 dage.
    </p>

    <h6>7. Reklamation</h6>
    <p>
      7.1. Anmeldelse af skade/manko skal ske skriftligt til på
      www.kontaktgls.dk.
    </p>
    <p>7.2. Der gælder følgende frister for reklamation:</p>
    <p>
      7.2.1. Ved skade på eller manko i indhold, skal kunden eller modtager
      reklamere til GLS inden for 5 hverdage efter levering, idet samtlige krav
      ellers fortabes.
    </p>
    <p>
      7.2.2. Ved manglende eller forsinket levering skal kunden eller modtager
      reklamere til GLS senest 6 måneder år fra afsendelsesdato. GLS må søge
      efter en pakke i op til 10 hverdage regnet fra dagen for efterlysning.
    </p>
    <p>
      7.3. GLS skal have fotos af beskadigede pakker. Findes der ikke fotos, kan
      GLS forlange, at modtager skal stille den beskadigede pakke klar til
      afhentning på leveringsadressen i original emballage. Er dette ikke
      muligt, mister kunden og/eller modtager retten til erstatning.
    </p>
    <p>
      7.4. Hvis den beskadigede pakke flyttes fra leveringsadressen/ modtagers
      adresse før det har været muligt for GLS, at besigtige pakken (jf. pkt.
      7.3), bortfalder retten til erstatning.
    </p>

    <h6>8. Forældelse</h6>
    <p>
      8.1. Ved skade på eller manko i gods skal der anlægges retssag mod GLS
      inden 1 år fra leveringsdato, jf. pkt. 2.4 – pkt. 2.6, eller fra den dag,
      hvor GLS har afsendt besked om forgæves leveringsforsøg, jf. pkt. 2.6.
    </p>
    <p>
      8.2. I andre tilfælde (bortkomst, forsinkelse, m.m.) skal retssag anlægges
      mod GLS inden 1 år fra den dag, hvor tabet tidligst kunne konstateres.
    </p>

    <h6></h6>
    <p></p>
    <p></p>

    <h6>9. Ansvarsbegrænsning</h6>
    <p>
      9.1. GLS er ansvarlig for pakken fra GLS PakkeShop modtager den (mod
      kvittering eller scanning), og til pakken er leveret, jf. pkt. 2.4 - pkt.
      2.6, eller indtil GLS berettiget afbryder distributionen.
    </p>
    <p>9.2. GLS’ ansvar er begrænset til følgende:</p>
    <p>
      9.2.1. GLS erstatter bortkomst eller beskadigelse med maks. 4.500 kr. pr.
      pakke. Erstatningsbeløbet kan dog maksimalt udgøre pakkens værdi.
    </p>
    <p>9.2.1.1. Værdiansættelse sker som følgende:</p>
    <p>
      9.2.1.1.1. Nye varer værdiansættes til den dokumenterede kostpris ekskl.
      moms.
    </p>
    <p>
      9.2.1.1.2. Brugte varer værdiansættes til den aktuelle omsætningsværdi
      eller den dokumenterede kostpris, hvor den laveste værdi er gældende.
    </p>
    <p>
      9.2.2. GLS kan ikke holdes ansvarlig forsinkelse, der skyldes forhold
      nævnt i pkt. 3.1 – pkt. 3.6. Hvis tab i forbindelse med bortkomst,
      beskadigelse eller forsinkelse skyldes handlinger eller undladelser fra
      kundens eller modtagers side, er GLS under ingen omstændigheder
      erstatningsansvarlig
    </p>
    <p>
      9.2.3. Ved forsinkelse erstatter GLS kun fragtprisen for den forsinkede
      pakke. GLS erstatter kun ved forsinkelser på mere end 10 hverdage fra den
      forventede leveringsdato.
    </p>
    <p>
      9.2.4. GLS erstatter ikke skade på temperaturfølsomme varer, der
      beskadiges, går til grunde eller kvalitetsforringes, som følge af
      temperaturudsving.
    </p>
    <p>9.2.5. GLS erstatter ikke yderemballage.</p>
    <P
      >9.2.6. GLS erstatter ikke kontanter, gavekort, lodsedler, billetter,
      skrabelodder og lignende.</P
    >
    <P
      >9.3. GLS erstatter ikke indirekte tab, driftstab, tabt fortjeneste eller
      andre tab. GLS er ikke ansvarlig for tab som følge af omstændigheder, der
      kan karakteriseres som force majeure, arbejdsstandsning, strejke, krig,
      terrorhandlinger, naturkatastrofer, cyberangreb m.v.</P
    >

    <h6>10. Persondata</h6>
    <P
      >10.1. Det er kundens ansvar at sikre de af GLS ønskede persondata, er
      korrekte og lovligt kan overdrages til GLS.</P
    >
    <P
      >10.2. Alle persondata, som GLS modtager, er omfattet af GLS’
      databeskyttelsespolitik, som til enhver tid fremgår på GLS’ hjemmeside</P
    >

    <h6>11. Værneting</h6>
    <P
      >11.1. Alle tvister om disse Handelsbetingelser skal afgøres efter dansk
      ret.</P
    >
    <P
      >11.2. Retten er i Kolding skal som første instans være værneting for alle
      tvister om disse Handelsbetingelser</P
    >

    <h6>12. Klagemulighed</h6>
    <P
      >12.1. En klage over en vare eller tjenesteydelse købt hos GLS kan
      indgives til Konkurrence- og Forbrugerstyrelsens Center for Klageløsning,
      Carl Jacobsens Vej 35, 2500 Valby. Du kan klage til Center for
      Klageløsning via <a href="www.forbrug.dk"></a>www.forbrug.dk.</P
    >
    <P></P>
  </div> -->
</template>

<script>
export default {
  name: "Handelsbetingelser",
  created(){
      //this.$gtag.pageview(this.$route);
  }

};
</script>

<style lang="scss" scoped>
iframe {
  position: fixed;
  top: 10vh;
  width: 50vw;
  height: 50vh;
  @media screen and (max-width: 450px) {
  top: 10vh;
  width: 100vw;
  height: 100vh;  
  }
}
</style>
