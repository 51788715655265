<template>
  <div class="about" v-if="cart.length > 0">
    <section class="container cartalert">
      <h1  style="font-weight:700;" class="text-center">
        Din indkøbskurv
         <!--<a
          v-if="cart && cart.find(x => x.id === 0) == null"
          class="zero"
          @click="resetCart"
          href="#!"
          >(Nulstil kurv)</a
        >-->
      </h1>
      <b-alert class="alertbox" show>
        <div class="row">
          <b-form class="col-md-6 col-lg-6 col-sm-12">
            <h5 style="font-size:18px;">
            <!--<strong
                >Faktura og QR koder sendes til denne mail så tjek venligst, at
                din e-mail og dit telefonnummer er korrekt som bruges ved evt.
                fortrydelse af køb.</strong
              >-->
              
                Tjek at din e-mail og telefonnummer er korrekt.<br/>
                Du modtager faktura og QR-kode efter din betaling.<br/>
                QR-koden skal bruges, når du afleverer din pakke i GLS PakkeShop.
              
            </h5>
            <div class="row">
              <div class="col-12">
                <label class="labelsName">Navn</label
                ><a
                  href="#!"
                  @click="changeBilling"
                  class="float-right text-primary"
                  >Rediger</a
                >
                <b-form-input
                  v-model.trim="$v.form.name.$model"
                  :state="validateState('name')"
                  size="sm"
                  aria-describedby="name-required"
                  :disabled="edit"
                ></b-form-input>
                <b-form-invalid-feedback id="name-required">
                  Navn skal udfyldes og må Maks. indeholde 40 tegn.
                </b-form-invalid-feedback>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-8 mr-0">
                    <label class="labelsName">Adresse</label>
                    <b-form-input
                      v-model.trim="$v.form.address.$model"
                      :state="validateState('address')"
                      size="sm"
                      aria-describedby="address-required"
                      :disabled="edit"
                    ></b-form-input>
                    <b-form-invalid-feedback id="address-required">
                      Adresse skal udfyldes og må Maks. indeholde 40 tegn.
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-4 pl-0">
                    <label class="labelsName">Husnr.</label>
                    <b-form-input
                      v-model.trim="$v.form.houseNumber.$model"
                      :state="validateState('houseNumber')"
                      size="sm"
                      aria-describedby="house-required"
                      :disabled="edit"
                    ></b-form-input>
                    <b-form-invalid-feedback id="house-required">
                      Husnr. skal udfyldes og må Maks. indeholde 50 tegn.
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-4 mr-0">
                    <label class="labelsName">Postnr.</label>
                    <b-form-input
                      v-model.trim="$v.form.zipcode.$model"
                      :state="validateState('zipcode')"
                      size="sm"
                      aria-describedby="zipcode-required"
                      :disabled="edit"
                    ></b-form-input>
                    <b-form-invalid-feedback id="zipcode-required">
                      Postnr. skal udfyldes og må Maks. indeholde 4 tal.
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-8 pl-0">
                    <label class="labelsName">By</label>
                    <b-form-input
                      v-model.trim="$v.form.city.$model"
                      :state="validateState('city')"
                      size="sm"
                      aria-describedby="city-required"
                      :disabled="edit"
                    ></b-form-input>
                    <b-form-invalid-feedback id="city-required">
                      By skal udfyldes og må Maks. indeholde 20 tegn.
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <label class="labelsName">Mobilnr.</label>
                <b-form-input
                  v-model.trim="$v.form.phone.$model"
                  :state="validateState('phone')"
                  size="sm"
                  aria-describedby="phone-required"
                  :disabled="edit"
                ></b-form-input>
                <b-form-invalid-feedback id="phone-required">
                  Mobilnr. skal udfyldes og skal bestå af 8 tal.
                </b-form-invalid-feedback>
              </div>
              <div class="col-12">
                <label class="labelsName">Mail</label>
                <b-form-input
                  v-model.trim="$v.form.email.$model"
                  :state="validateState('email')"
                  size="sm"
                  aria-describedby="email-required"
                  @paste="onPaste"
                  :disabled="edit"
                ></b-form-input>
                <b-form-invalid-feedback id="email-required">
                  Mail skal udfyldes og må Maks. indeholde 100 tegn.
                </b-form-invalid-feedback>
              </div>
              <div class="col-12">
                <label class="labelsName"><strong>Bekræft mail</strong></label>
                <b-form-input
                  v-model.trim="$v.form.confirmEmail.$model"
                  :state="validateState('confirmEmail')"
                  size="sm"
                  aria-describedby="confirm-required"
                  @paste="onPaste"
                  :disabled="confirm"
                ></b-form-input>
                <b-form-invalid-feedback id="confirm-required">
                  Den indtastede mailadressser matcher ikke dit Mail felt. Tjek venligst
                  igen, da du ellers ikke vil få dine
                  QR koder tilsendt.
                </b-form-invalid-feedback>
              </div>
              <div class="col-12">
                <b-button
                  variant="primary"
                  @click="confirmForm"
                  class="float-right mt-1 rounded-pill no-outline"
                  :disabled="$v.$invalid"
                  v-if="!confirm"
                  >Bekræft</b-button
                >
                <b-button
                  variant="primary"
                  @click="confirm = false"
                  class="float-right mt-1 rounded-pill  no-outline"
                  v-else
                  >Rediger</b-button
                >
              </div>
            </div>
          </b-form>
        </div>
      </b-alert>
    </section>
    <section class="container cartitems">
      <CartItem
        v-for="(item, index) in cart.filter(x => x.id > 0)"
        :key="item.id"
        :parcel="item"
        :row="index + 1"
      />
    </section>
    <section class="container total mt-5">
      <div class="row button-row">
        <div class=" col-sm-12 col-md-6 back-button">
          <b-button
            variant="white"
            class="action backbutton-action btn-outline-primary rounded-pill"
            @click="$router.push('/')"
            >Tilbage</b-button
          >
        </div>
        <div class=" col-sm-12 col-md-6 totals">
          <div class="totals-rows">
            <label class="total-text">I alt {{ total }} DKK</label>
            <div v-html="html" v-if="confirm"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CartItem from "@/components/CartItem";
import { mapState } from "vuex";
import { LabelOnlineService } from "@/services/LabelOnlineService";
import {
  email,
  maxLength,
  minLength,
  required,
  sameAs
} from "vuelidate/lib/validators";

export default {
  components: { CartItem },
  data() {
    return {
      confirm: false,
      form: {
        name: null,
        address: null,
        houseNumber: null,
        zipcode: null,
        city: null,
        phone: null,
        email: null,
        confirmEmail: null
      },
      edit: true,
      html: null,
      billingModal: false,
      billing: {
        name: "",
        streetname: "",
        housenumber: "",
        zipcode: "",
        city: "",
        phone: "",
        mail: "",
        country: "208",
        cartId: this.cartId
      },
      state: {
        name: null,
        streetname: null,
        housenumber: null,
        zipcode: null,
        city: null,
        phone: null,
        mail: null
      }
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(40)
      },
      address: {
        required,
        maxLength: maxLength(50)
      },
      houseNumber: {
        required,
        maxLength: maxLength(50)
      },
      zipcode: {
        required,
        maxLength: maxLength(4),
        minLength: minLength(4)
      },
      city: {
        required,
        maxLength: maxLength(30)
      },
      phone: {
        required,
        maxLength: maxLength(8),
        minLength: minLength(8)
      },
      email: {
        required,
        email,
        maxLength: maxLength(100)
      },
      confirmEmail: {
        required,
        sameAs: sameAs(function() {
          return this.$v.form.email.$model;
        })
      }
    }
  },
  async created() {
      //this.$gtag.pageview(this.$route);
    const { data } = await LabelOnlineService.GetCartConfig({
      cartId: localStorage.getItem("cartId")
    });
    if (this.cart[0]) {
      if (this.customInvoice == null) {
        this.$v.form.name.$model = this.cart[0].afsender.afsendernavn;
        this.$v.form.address.$model = this.cart[0].afsender.afsendervejnavn;
        this.$v.form.houseNumber.$model = this.cart[0].afsender.afsenderhusnr;
        this.$v.form.zipcode.$model = this.cart[0].afsender.afsenderpostnr;
        this.$v.form.city.$model = this.cart[0].afsender.afsendercity;
        this.$v.form.phone.$model = this.cart[0].afsender.afsenderphonenr;
        this.$v.form.email.$model = this.cart[0].afsender.afsendermail;
      } else {
        this.$v.form.name.$model = this.customInvoice.name;
        this.$v.form.address.$model = this.customInvoice.address;
        this.$v.form.houseNumber.$model = this.customInvoice.houseNumber;
        this.$v.form.zipcode.$model = this.customInvoice.zipcode;
        this.$v.form.city.$model = this.customInvoice.city;
        this.$v.form.phone.$model = this.customInvoice.phone;
        this.$v.form.email.$model = this.customInvoice.email;
        this.$v.form.confirmEmail.$model = this.customInvoice.confirmEmail;
      }
      await LabelOnlineService.UpdateInvoice(
        this.$v.form.name.$model,
        this.$v.form.address.$model,
        this.$v.form.houseNumber.$model,
        this.$v.form.zipcode.$model,
        this.$v.form.city.$model,
        this.$v.form.phone.$model,
        this.$v.form.email.$model,
        "208",
        this.cartId
      );
    }
    this.html = data.form;
  },
  async mounted() {
    if (this.$route.query.payment) {
      if (this.$route.query.payment === "bambora") {
        await this.resetCart();
      }
    }
  },
  computed: {
    ...mapState("parcels", ["cart", "cartId", "countries", "customInvoice"]),
    total() {
      let sum = 0;
      this.cart.map(x => {
        sum += x.package.price;
      });
      return sum;
    }
  },
  methods: {
    async resetCart() {
      await this.$store.dispatch('parcels/getCart');
      const { data } = await LabelOnlineService.resetCart({ cartLines:this.cart, cartId:this.cartId});
      if (data) {
        localStorage.setItem("cartId", data.newGuid);
        await this.$store.commit("parcels/SET_CARTID", data.newGuid);
      }

    },
    async confirmForm() {
      if (!this.$v.$invalid) {
        const cartConfig = await LabelOnlineService.GetCartConfig({
          cartId: localStorage.getItem("cartId")
        });
        this.html = cartConfig.data.form;
        await LabelOnlineService.UpdateInvoice(
          this.$v.form.name.$model,
          this.$v.form.address.$model,
          this.$v.form.houseNumber.$model,
          this.$v.form.zipcode.$model,
          this.$v.form.city.$model,
          this.$v.form.phone.$model,
          this.$v.form.email.$model,
          "208",
          this.cartId
        );
        this.confirm = true;
        this.edit = true;
        this.$store.dispatch("parcels/updateInvoice", this.form);
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onPaste(e) {
      e.preventDefault();
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();

      this.state.name = valid;
      this.state.streetname = valid;
      this.state.housenumber = valid;
      this.state.zipcode = valid;
      this.state.city = valid;
      this.state.phone = valid;
      this.state.mail = valid;
      return valid;
    },
    resetModal() {
      this.state = {
        name: null,
        streetname: null,
        housenumber: null,
        zipcode: null,
        city: null,
        phone: null,
        mail: null
      };
    },
    changeBilling() {
      //this.billingModal = true;
      this.edit = !this.edit;
      this.confirm = false;
    },
    async updateBilling(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (!this.checkFormValidity()) {
        return;
      }
      await LabelOnlineService.UpdateInvoice(
        this.billing.name,
        this.billing.streetname,
        this.billing.housenumber,
        this.billing.zipcode,
        this.billing.city,
        this.billing.phone,
        this.billing.mail,
        this.billing.country,
        this.cartId
      );
      this.$store.dispatch("parcels/updateInvoice", this.form);
    }
  }
};
</script>
<style scoped>
label {
  margin-top: 5px;
}
.about {
  min-height: 811px;
}
.backbutton {
  bottom: 0px;
}
.paymentbutton a.underline:hover{
  color:#00c2f0 !important;
}
</style>
