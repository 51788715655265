<template>
  <div></div>
</template>

<script>
import { LabelOnlineService } from "@/services/LabelOnlineService";
import { mapState } from "vuex";

export default {
  name: "ReturnLabel",
  created() {

      //this.$gtag.pageview(this.$route);

  },
  async mounted(){
    const urlParams = new URLSearchParams(window.location.search);
    const guid = urlParams.get("guid");

    const { data } = await LabelOnlineService.fetchPredefined(guid);

    const self = this;
    setTimeout(()=>{
      const parcel = self.cart.filter(x => x.id === 0)[0]
      if(parcel != null) {

        parcel.modtager.modtagerfirma = data.companyName;
        parcel.modtager.modtagernavn = data.careOfInfo;
        parcel.modtager.modtagervejnavn = data.streetName;
        parcel.modtager.modtagerhusnr = data.houseNumber;
        parcel.modtager.modtagerpostnr = data.zipcode;
        parcel.modtager.modtagercity = data.zipName;
        parcel.modtager.modtagercountry = -1;
        parcel.modtager.modtagerphonenr = "00000000";
        self.$router.push("/")
      }
    },500);

  },
  computed: {
    ...mapState("parcels", ["cart"])
  }
};
</script>

<style scoped></style>
