<template>
  <div class="home">
    <div class="main">
      <div class="textarea">
        <h1>
          Kære besøgende,
        </h1>
        <p>
          Din internetbrowser er forældet og kan derfor ikke vise hjemmesiden
          korrekt. Vi anbefaler i stedet:
        </p>
        <ul>
          <li>
            <a
              target="_blank"
              href="https://www.google.com/chrome/browser/desktop/"
            >
              <img
                src="../assets/chrome.png"
                height="22"
                alt="Google Chrome"
              />
              Google Chrome
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.mozilla.org/firefox/new/"
              ><img
                src="../assets/firefox.png"
                height="22"
                alt="Firefox"
              />
              Firefox</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.microsoft.com/da-dk/edge"
              ><img
                src="../assets/edge.png"
                height="22"
                alt="Edge"
              />
              Microsoft Edge</a
            >
          </li>
        </ul>
        <p></p>
        <p>
          Hvis du ikke har en af overstående internetbrowsere, kan du nemt og
          sikkert hente
          <a href="https://www.google.com/chrome/browser/desktop/"
            >Google Chrome her.</a
          >
        </p>
      </div>
      <div class="picture">
        <img src="../assets/gls-image.jpg" alt="gls" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotSupported",
  created(){
      //this.$gtag.pageview(this.$route);
  }
};
</script>

<style scoped>

h1 {
  letter-spacing: 3px;
}

a {
  color: #01965B;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0;
}

p a {
  font-weight: bold;
  border-bottom: solid 1px;
}

ul {
  border-top: solid 2px #e4e4e4;
  padding: 15px 0 5px;
  border-bottom: solid 2px #e4e4e4;
  margin: 20px 0;
  width: auto;
}

li {
  list-style: none;
  margin: 0 0 10px;
}

.header {
  height: auto;
  padding: 50px;
  text-align: center;
}

.header-inner {
  max-width: 1200px;
  margin: auto;
  display: block;
  margin: auto;
  padding: 0 50px;
}

.main {
  padding: 50px;
  display: block;
  max-width: 1200px;
  margin: auto;
}

p, li {
  line-height: 36px;
  font-size: 22px;
}

li a {
  color: black;
}

li a:hover {
  color: #01965B;
}

li img {
  margin-right: 10px;
  top: 3px;
  position: relative;
  border: none;
}

.picture {
  max-width: 600px;
  padding-top: 85px;
}

.picture img {
  width: 100%;
}

.textarea {
  width: 50%;
  float: left;
  padding-right: 50px;
  margin: 50px 0 0;
}

.picture {
  float: right;
  width: 50%;
}

* {
  box-sizing: border-box;
}

@media screen and (max-width:1200px) {
  p, li {
    font-size: 16px !important;
    line-height: 30px !important;
  }

  .picture {
    max-width: 100%;
  }

  .header-inner {
    padding: 0;
  }
}

@media screen and (max-width:800px) {
  .main {
    flex-direction: column;
  }

  .picture {
    width: 100%;
    padding-top: 20px;
  }

  .picture img {
    width: 100%;
  }

  .textarea {
    width: 100%;
    padding: 0;
    margin: 0 0 20px;
  }

  .main {
    padding: 0 20px;
  }

  .header {
    padding: 50px 0;
  }

  .header-inner {
    text-align: center;
  }
}
</style>
