<template>
  <div class="home container">
    <div style="margin-top:100px; text-align:left">
      <h3 class="text-center">Fortrydelse af din ordre</h3>
      <div>
        <br />
        <template v-if="!noneToReturn">
          <div class="form-text" v-if="orderLines.length === 0">
            Indtast ordrenr. og mail, så sender vi en kode til dit mobilnummer. Indtast derefter koden på denne side for at fortryde køb af labels.
          </div>
          <br />

          <div v-if="orderLines.length === 0" class="formbox">
            <div>
              <small class="form-text">Udfyld ordrenummer:</small>
              <input
                type="text"
                class="form-control"
                placeholder="Ordrenummer"
                v-model.trim="ordreNr"
                :disabled="showCodeField"
              />
            </div>
            <div>
              <small class="form-text">Udfyld mail:</small>
              <input
                type="text"
                class="form-control"
                placeholder="Mail"
                v-model.trim="mail"
                :disabled="showCodeField"
              />
            </div>
            <div>
              <br />
              <button
                v-if="!showCodeField"
                type="button"
                class="btn btn-primary btn"
                @click="getCode()"
              >
                Hent kode
              </button>
            </div>

            <div v-if="!showCodeField">
              <small class="form-text" style="color:red; font-size:16px">{{
                confirmMailErrorText
              }}</small>
            </div>

            <div v-if="showCodeField && orderLines.length == 0">
              <div>
                <small class="form-text"
                  >Indtast koden, som du modtager på sms:</small
                >
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">G-</span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="kode"
                    v-model.trim="code"
                  />
                </div>
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-primary btn"
                  @click="getParcels()"
                  style="width:150px; font-size:12px;  font-family: Avenir, Helvetica, Arial, sans-serif; margin-top:20px "
                >
                  Godkend
                </button>
              </div>
            </div>
          </div>
          <template v-if="!refunded && orderLines.length > 0">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Vælg</th>
                    <th scope="col">Pakkenr.</th>
                    <th scope="col">Pris</th>
                    <th scope="col">Modtager</th>
                    <th scope="col">Adresse</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="ol in orderLines" :key="ol.id">
                    <td><input type="checkbox" v-model="ol.selected" /></td>
                    <th scope="row">{{ ol.parcelNumber }}</th>
                    <td>{{ ol.price }} DKK</td>
                    <td>{{ ol.receiverName }}</td>
                    <td>{{ ol.receiverAddress }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <button
                v-if="
                  orderLines.filter(x => x.selected).length > 0 &&
                    !capturedFailed
                "
                class="btn btn-primary btn"
                @click="cancelParcels()"
              >
                Fortryd køb af {{ orderLines.filter(x => x.selected).length }}
                {{
                  orderLines.filter(x => x.selected).length > 1
                    ? " labels"
                    : " label"
                }}
              </button>
            </div>
          </template>
        </template>
        <template v-if="noneToReturn">
          <strong
            >Du har ingen pakker som kan refunderes, de er enten allerede
            refunderet eller blevet scannet i vores system i forbindelse med
            pakke indlevering.</strong
          >
        </template>
        <template v-if="refunded">
          <h3>
            Tak for det, din ordrer vil blive tilbageført til dit kort eller
            bank konto
          </h3>
          <p style="font-size:16px;">Obs: der kan gå op til 7 bankdage.</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { LabelOnlineService } from "@/services/LabelOnlineService";

export default {
  name: "Return",

  data() {
    return {
      ordreNr: "",
      mail: "",
      parcelnrList: [],
      checkedList: [],
      processFailed: false,
      showCodeField: false,
      code: "",
      orderLines: [],
      capturedFailed: null,
      refunded: false,
      confirmMailErrorText: "",
      noneToReturn: false
    };
  },
  created(){
      //this.$gtag.pageview(this.$route);
  },
  methods: {
    async getParcels() {
      this.noneToReturn = false;
      // call api method to get parcels that can be canceled
      const { data } = await LabelOnlineService.checkCode({
        orderId: this.ordreNr,
        email: this.mail,
        code: this.code
      });
      this.orderLines = data.orderLines;
      this.noneToReturn = data.orderLines.length === 0;
    },
    async getCode() {
      if (this.ordreNr != "" && this.mail != "") {
        // call api method to generate code
        //show component to pass code
        this.showCodeField = true;
        await LabelOnlineService.confirmReturn({
          orderId: this.ordreNr,
          email: this.mail
        }).catch(this.confirmMailErrors);
      } else {
        this.confirmMailErrorText = "Udfyld ordrenummer og mail";
        this.showCodeField = false;
      }
    },
    confirmMailErrors(err) {

      if (err.response.status == 500) {
        this.confirmMailErrorText =
          "Vores server er i øjebliket nede. Prøv igen senere eller kontakt kundeservice";
      } else {
        
        this.confirmMailErrorText =
          "Der kunne ikke findes en ordre med de angivne informationer - prøv igen";
      }
      this.showCodeField = false;
    },
    async cancelParcels() {
      var ol = this.orderLines
        .filter(x => x.selected)
        .map(x => {
          return x.id;
        });

      try {
        const { data } = await LabelOnlineService.cancelParcels({
          orderId: this.ordreNr,
          email: this.mail,
          orderLines: ol
        });
        if (data.statusCode === 409) {
          this.capturedFailed = data;
        }
        this.refunded = true;
      }catch(e){
        console.error.log(e)
      }
    },
    async cancelParcelsWithAccountNumber() {
      await LabelOnlineService.accountRefund(this.capturedFailed);
      this.refunded = true;
    }
  }
};
</script>
<style scoped>
.formbox {
  width: 350px;
}
.home {
  min-height: 951px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
h3 {
  font-family: "TheSansGLS";
  font-weight: 700;
  color: #172154;
  margin-top: 50px;
}
</style>
